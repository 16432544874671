import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'
import moment from 'moment-timezone'

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function DeliveryModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const delivery = props.delivery
    const companies = props.companies
    const campaigns = props.campaigns

    console.log(delivery.startDate)
    console.log(moment(delivery.startDate).format('YYYY-MM-DD'))

    const [company, setCompany] = useState(delivery.companyId)
    const [campaign, setCampaign] = useState(delivery.campaignId)
    //const [source, setSource] = useState(delivery.source)
    //const [startDate, setStartDate] = useState(moment(delivery.startDate).format('YYYY-MM-DD'))
    const [leadPrice, setLeadPrice] = useState(delivery.leadPrice)
    //const [vatRate, setVatRate] = useState(delivery.vatRate)
    const [allocation, setAllocation] = useState(delivery.allocation)
    const [leadbyteNumber, setLeadbyteNumber] = useState(delivery.leadbyteNumber ? delivery.leadbyteNumber : '')
    const [active, setActive] = useState(delivery.active)
    
    async function updateDelivery() {
        try {
            // let formattedStartDate = new Date(startDate)
            // console.log('formatted start date: ', formattedStartDate)

            try {
                let updatedDelivery = await api.updateDelivery(delivery.companyId, delivery.product, leadPrice, allocation, leadbyteNumber, active)
                console.log('delivery: ', updatedDelivery)

                props.updateDelivery(updatedDelivery)
                props.showSuccessAlert('Delivery updated!')
                props.close()
            } catch(e) {
                onError(e)
            }
  		} catch(e) {
  			onError(e)
  		}
    }

    // function updateStartDate(date) {
    //     console.log(date)
    //     setStartDate(date)
    // }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Update Delivery
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                    <FormLabel>Company</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                            disabled
                        >
                            {companies.map((company) => {
                                return (
                                    <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Campaign</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={campaign}
                            onChange={(e) => setCampaign(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                            disabled
                        >
                            {campaigns.map((campaign) => {
                                return (
                                    <MenuItem value={campaign.campaignId}>{campaign.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                {/*
                <FormGroup>
                    <FormLabel>Source</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='insureyourincome.ie'>insureyourincome.ie</MenuItem>
                            <MenuItem value='lifecompare.ie'>lifecompare.ie</MenuItem>
                            <MenuItem value='unlockyourpension.ie'>unlockyourpension.ie</MenuItem>
                            <MenuItem value='nationalpensionhelpline.ie'>nationalpensionhelpline.ie</MenuItem>
                            <MenuItem value='stoppayingtoomuch.ie'>stoppayingtoomuch.ie</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                */}
                {/*
				<FormGroup>
                    <FormLabel>Start Date</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={startDate}
                        defaultValue={startDate}
                        onChange={(e) => updateStartDate(e.target.value)}
                    />
                </FormGroup>
                */}
                <FormGroup>
                  	<FormLabel>Lead Price (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={leadPrice}
                    	onChange={(e) => setLeadPrice(e.target.value)}
                  	/>
                </FormGroup>
                {/*
                <FormGroup>
                    <FormLabel>VAT Rate</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={vatRate}
                            onChange={(e) => setVatRate(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='Inclusive'>23% Inclusive (included in lead price)</MenuItem>
                            <MenuItem value='Exclusive'>23% Exclusive (not included in lead price)</MenuItem>
                            <MenuItem value='None'>0% (no VAT)</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                */}
                <FormGroup>
                  	<FormLabel>Allocation</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={allocation}
                    	onChange={(e) => setAllocation(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Leadbyte Number</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={leadbyteNumber}
                    	onChange={(e) => setLeadbyteNumber(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Active</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={active}
                            onChange={(e) => setActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
			</DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => updateDelivery()}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}